import gql from "graphql-tag";

export interface LearningGoal {
  id: string;
  description: string; // (basic) markdown
  teacher_content?: string; // (basic) markdown
  hide?: boolean;
}

export interface ContentMeta {
  id: string;
  title: string;
  description: string;
  path: string;
  tags: string[];

  new_terminology?: string[];
  learning_goals?: LearningGoal[];

  sections?: Array<{
    id: string;
    title: string;
    description: string;
    path: string;
    tags: string[];
  }>;
}

export const ContentMeta_fragment = gql`
  fragment ContentMeta on CourseContent {
    id
    title
    description
    path
    tags

    new_terminology
    learning_goals {
      id
      description
      teacher_content
      hide
    }

    sections {
      id
      title
      description
      path
      tags
    }
  }
`;

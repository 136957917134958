import React from "react";

import { useAuth } from "~/lib/auth";

interface Props {
  children?: React.ReactNode | (() => React.ReactNode);
}

export default function AsTeacher({ children = null }: Props) {
  const { me } = useAuth();

  if (me && (me.teacher || me.admin)) {
    const content = typeof children === "function" ? children() : children;
    return <>{content}</>;
  }

  return null;
}

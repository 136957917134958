import styled from "styled-components";

export const Button = styled.button`
  padding: 0.5rem 0.75rem;
  font-size: inherit;
  cursor: pointer;

  background: ${p => p.theme.baseColors.red};
  color: ${p => p.theme.baseColors.white};
  border: none;
  border-radius: 8px;

  transition: transform 0.2s ease;

  &:focus {
    outline: none;
  }

  &:hover {
    opacity: 0.9;
  }

  &:active {
    opacity: 1;
    transform: scale(0.95);
  }

  &[disabled],
  &[disabled]:hover {
    background: ${p => p.theme.baseColors.gray700};
    opacity: 0.6;
    transform: scale(1);
  }
`;

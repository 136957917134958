import styled, { css } from "styled-components";

const common = css`
  display: block;
  width: 100%;
  box-sizing: border-box;
  font-size: inherit;
  line-height: inherit;

  resize: vertical; /* for textareas */

  padding: 0.5rem 0.75rem;

  border: 2px solid ${p => p.theme.borderColor[0]};
  background-color: ${p => p.theme.colors.backgroundAlt};
  border-radius: 8px;
  color: ${p => p.theme.colors.text};

  &:focus {
    outline: none;
    border-color: ${p => p.theme.borderColor[1]};
  }
`;

export const TextField = styled.input`
  ${common};
`;

export const TextareaField = styled.textarea`
  ${common};
`;

export const SelectField = styled.select`
  ${common};
  height: 2.5rem;
`;

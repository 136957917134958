import { IconType, GenIcon } from "react-icons/lib/cjs/iconBase";

export {
  IoIosArrowDown as ArrowDownIcon,
  IoIosArrowBack as ArrowLeftIcon,
  IoIosArrowForward as ArrowRightIcon,
  IoIosArrowUp as ArrowUpIcon,
  IoMdEyeOff as EyeDisabled,
  IoMdEye as EyeEnabled,
  IoMdMenu as MenuIcon,
  IoMdSunny as SunIcon,
  IoMdMoon as MoonIcon,
  IoIosListBox as PresenceListIcon
} from "react-icons/io";

export { MdAccountBox as AccountIcon } from "react-icons/md";

export const SearchIcon: IconType = GenIcon({
  tag: "svg",
  attr: { viewBox: "-4 -4 24 24" },
  child: [
    {
      tag: "path",
      attr: {
        d:
          "M7,0 C10.8659932,0 14,3.13400675 14,7 C14,8.57591349 13.4792342,10.0301956 12.6004036,11.2001452 C12.6375836,11.2281271 12.6732567,11.2590431 12.7071068,11.2928932 L15.7071068,14.2928932 C16.0976311,14.6834175 16.0976311,15.3165825 15.7071068,15.7071068 C15.3165825,16.0976311 14.6834175,16.0976311 14.2928932,15.7071068 L11.2928932,12.7071068 C11.2590431,12.6732567 11.2281271,12.6375836 11.2001452,12.6004036 C10.0301956,13.4792342 8.57591349,14 7,14 C3.13400675,14 0,10.8659932 0,7 C0,3.13400675 3.13400675,0 7,0 Z M7,2 C4.23857625,2 2,4.23857625 2,7 C2,9.76142375 4.23857625,12 7,12 C9.76142375,12 12,9.76142375 12,7 C12,4.23857625 9.76142375,2 7,2 Z"
      },
      child: []
    }
  ]
});

export const SansIcon: IconType = GenIcon({
  tag: "svg",
  attr: { viewBox: "0 0 40 40" },
  child: [
    {
      tag: "path",
      attr: {
        d:
          "M18.1211 17.875H12.8477V36H8.23828V17.875H4.68359V15.7266L8.23828 14.3203V12.9141C8.23828 10.362 8.83724 8.47396 10.0352 7.25C11.2331 6.01302 13.0625 5.39453 15.5234 5.39453C17.138 5.39453 18.7266 5.66146 20.2891 6.19531L19.0781 9.67188C17.9453 9.30729 16.8646 9.125 15.8359 9.125C14.7943 9.125 14.0326 9.45052 13.5508 10.1016C13.082 10.7396 12.8477 11.7031 12.8477 12.9922V14.3984H18.1211V17.875ZM32.6328 17.875H27.3594V36H22.75V17.875H19.1953V15.7266L22.75 14.3203V12.9141C22.75 10.362 23.349 8.47396 24.5469 7.25C25.7448 6.01302 27.5742 5.39453 30.0352 5.39453C31.6497 5.39453 33.2383 5.66146 34.8008 6.19531L33.5898 9.67188C32.457 9.30729 31.3763 9.125 30.3477 9.125C29.306 9.125 28.5443 9.45052 28.0625 10.1016C27.5938 10.7396 27.3594 11.7031 27.3594 12.9922V14.3984H32.6328V17.875Z"
      },
      child: []
    }
  ]
});

export const SerifIcon: IconType = GenIcon({
  tag: "svg",
  attr: { viewBox: "0 0 40 40" },
  child: [
    {
      tag: "path",
      attr: {
        d:
          "M12.6 29.36C12.6 30.3467 12.64 31.16 12.72 31.8C12.8267 32.44 12.9867 32.96 13.2 33.36C13.4133 33.7333 13.7067 34 14.08 34.16C14.48 34.2933 14.9867 34.3733 15.6 34.4V36H3.48V34.4C4.28 34.3733 4.90667 34.3067 5.36 34.2C5.84 34.0667 6.2 33.84 6.44 33.52C6.68 33.1733 6.84 32.7067 6.92 32.12C7 31.5067 7.04 30.6933 7.04 29.68V17.72H2.76V15.44H7.04V14.16C7.04 12.6667 7.28 11.32 7.76 10.12C8.24 8.89333 8.92 7.86667 9.8 7.04C10.68 6.18667 11.7467 5.53333 13 5.08C14.2533 4.62667 15.6667 4.4 17.24 4.4C18.2533 4.4 19.1067 4.50667 19.8 4.72C20.52 4.90666 21.2267 5.24 21.92 5.72C22.4 5.29333 22.9867 4.90666 23.68 4.56C24.3733 4.18667 25.1333 3.86667 25.96 3.6C26.7867 3.33333 27.6533 3.13333 28.56 3C29.4667 2.86666 30.3733 2.8 31.28 2.8C33.12 2.8 34.5733 3.12 35.64 3.76C36.7333 4.4 37.28 5.26666 37.28 6.36C37.28 7.08 37.08 7.61333 36.68 7.96C36.28 8.30667 35.8133 8.48 35.28 8.48C34.64 8.48 34.08 8.34667 33.6 8.08C33.12 7.81333 32.68 7.41333 32.28 6.88C31.7467 6.18667 31.32 5.72 31 5.48C30.68 5.24 30.32 5.12 29.92 5.12C29.36 5.12 28.84 5.24 28.36 5.48C27.88 5.69333 27.4667 6.08 27.12 6.64C26.7733 7.2 26.5067 7.94666 26.32 8.88C26.1333 9.78666 26.04 10.9333 26.04 12.32V15.44H32.32V17.72H26.04V29.36C26.04 30.3733 26.0933 31.2 26.2 31.84C26.3067 32.48 26.5067 32.9867 26.8 33.36C27.12 33.7333 27.5333 34 28.04 34.16C28.5467 34.2933 29.2 34.3733 30 34.4V36H18.04V34.4C18.5733 34.3733 19 34.3067 19.32 34.2C19.64 34.0667 19.88 33.8267 20.04 33.48C20.2267 33.1333 20.3467 32.6667 20.4 32.08C20.4533 31.4667 20.48 30.6667 20.48 29.68V17.72H12.6V29.36ZM20.48 15.44V9.6C19.6 8.42666 18.8133 7.65333 18.12 7.28C17.4533 6.90666 16.7333 6.72 15.96 6.72C14.68 6.72 13.8 7.34666 13.32 8.6C12.84 9.85333 12.6 11.6267 12.6 13.92V15.44H20.48Z"
      },
      child: []
    }
  ]
});

export const PinIcon: IconType = GenIcon({
  tag: "svg",
  attr: { viewBox: "0 0 24 24" },
  child: [
    {
      tag: "path",
      attr: {
        d:
          "M16.729 4.271c-.389-.391-1.021-.393-1.414-.004-.104.104-.176.227-.225.355-.832 1.736-1.748 2.715-2.904 3.293-1.297.64-2.786 1.085-5.186 1.085-.13 0-.26.025-.382.076-.245.102-.439.297-.541.541-.101.244-.101.52 0 .764.051.123.124.234.217.326l3.243 3.243-4.537 6.05 6.05-4.537 3.242 3.242c.092.094.203.166.326.217.122.051.252.078.382.078s.26-.027.382-.078c.245-.102.44-.295.541-.541.051-.121.077-.252.077-.381 0-2.4.444-3.889 1.083-5.166.577-1.156 1.556-2.072 3.293-2.904.129-.049.251-.121.354-.225.389-.393.387-1.025-.004-1.414l-3.997-4.02z"
      },
      child: []
    }
  ]
});

import merge from "deepmerge";
import { PrismTheme } from "prism-react-renderer";

const colors = {
  white: "#fff",
  gray_100: "#f8f9fa",
  gray_200: "#e9ecef",
  gray_300: "#dee2e6",
  gray_400: "#ced4da",
  gray_500: "#adb5bd",
  gray_600: "#6c757d",
  gray_700: "#495057",
  gray_800: "#343a40",
  gray_900: "#212529",
  black: "#000",
  darkgray: "#495057", // i.e. gray-700 // not in bootstrap
  darkblue: "#005296", // not in bootstrap
  blue: "#0088fa",
  indigo: "#6610f2", // bootstrap default
  purple: "#ba4ca1",
  pink: "#ba4ca1",
  red: "#c1272d",
  orange: "#ff7043",
  yellow: "#f6b822",
  green: "#2a7e5a",
  lightgreen: "#57ad5b", // not in bootstrap
  teal: "#26a69a",
  cyan: "#17a2b8", // bootstrap default
  brown: "#6d544b" // not in bootstrap
};

// SYNTAX THEMES

const syntax_default: PrismTheme = {
  plain: {
    color: colors.gray_800,
    backgroundColor: colors.gray_100
  },
  styles: [
    {
      types: ["comment", "prolog", "doctype", "cdata"],
      style: {
        color: colors.gray_500,
        fontStyle: "italic"
      }
    },
    {
      types: ["namespace"],
      style: {
        opacity: 0.7
      }
    },
    {
      types: ["string", "attr-value"],
      style: {
        color: colors.green
      }
    },
    {
      types: ["punctuation", "operator"],
      style: {
        color: colors.gray_600
      }
    },
    {
      types: [
        "entity",
        "url",
        "symbol",
        "number",
        "boolean",
        "variable",
        "constant",
        "property",
        "regex",
        "inserted"
      ],
      style: {
        color: colors.teal
      }
    },
    {
      types: ["atrule", "attr-name", "selector"],
      style: {
        color: colors.gray_800
      }
    },
    {
      types: ["function", "deleted"],
      style: {
        color: colors.red
      }
    },
    {
      types: ["function-variable"],
      style: {
        color: colors.gray_800
      }
    },
    {
      types: ["keyword"],
      style: {
        color: colors.teal
      }
    },
    {
      types: ["tag", "selector"],
      style: {
        color: colors.blue
      }
    }
  ]
};

const syntax_default_dark: PrismTheme = merge(syntax_default, {
  plain: {
    color: colors.gray_300,
    backgroundColor: colors.gray_900
  },
  styles: [
    {
      types: ["atrule", "attr-name", "selector"],
      style: {
        color: colors.gray_500
      }
    },
    {
      types: ["function", "deleted"],
      style: {
        color: colors.yellow
      }
    }
  ]
});

const syntax_shell: PrismTheme = {
  plain: {
    color: colors.white,
    backgroundColor: colors.gray_800
  },
  styles: [
    {
      types: ["comment", "prolog", "doctype", "cdata"],
      style: {
        color: colors.gray_600,
        fontStyle: "italic"
      }
    },
    {
      types: ["namespace"],
      style: {
        opacity: 0.7
      }
    },
    {
      types: ["string", "attr-value"],
      style: {
        color: colors.white
      }
    },
    {
      types: ["punctuation", "operator"],
      style: {
        color: colors.white
      }
    },
    {
      types: [
        "entity",
        "url",
        "symbol",
        "number",
        "boolean",
        "variable",
        "constant",
        "property",
        "regex",
        "inserted"
      ],
      style: {
        color: colors.white
      }
    },
    {
      types: ["atrule", "attr-name", "selector"],
      style: {
        color: colors.white
      }
    },
    {
      types: ["function", "deleted"],
      style: {
        color: colors.white
      }
    },
    {
      types: ["function-variable"],
      style: {
        color: colors.white
      }
    },
    {
      types: ["keyword"],
      style: {
        color: colors.white
      }
    },
    {
      types: ["tag", "selector"],
      style: {
        color: colors.white
      }
    }
  ]
};

export const codaisseurSyntaxThemes = {
  default: {
    light: syntax_default,
    dark: syntax_default_dark
  },
  shell: {
    light: syntax_shell,
    dark: syntax_shell
  }
};

import React from "react";
import { stripIndent } from "common-tags";

import Markdown from "~/components/Markdown";

interface Props {
  term?: string;
  hidden?: boolean;
  children?: React.ReactNode;
}

export function Term({ children, hidden }: Props) {
  if (hidden) return null;

  const content =
    typeof children === "string" ? (
      <Markdown inline source={stripIndent(children)} contentComponents={false} />
    ) : (
      children
    );

  return <em className="Term">{content}</em>;
}

import React from "react";
import styled, { keyframes } from "styled-components";

type Props = {
  state?: "idle" | "loading";
  message?: React.ReactNode;
  children?: React.ReactNode;
};

export function Loader({ state = "loading", message = null, children = null }: Props) {
  return (
    <LoaderBox state={state}>
      <Overlay>
        {state === "loading" && <Spinner />}
        {message && <p style={{ marginTop: "1rem" }}>{message}</p>}
        {children}
      </Overlay>
    </LoaderBox>
  );
}

const LoaderBox = styled.div<{ state: "idle" | "loading" }>`
  min-height: 20rem;
  position: relative;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.div`
  position: relative;
  height: 4rem;

  &::before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 4rem;
    height: 4rem;
    margin-top: -2rem;
    margin-left: -2rem;
    border-radius: 50%;
    border: 0.2rem solid transparent;
    border-top-color: ${p =>
      p.theme.darkMode ? p.theme.baseColors.yellow : p.theme.baseColors.red};
    animation: ${rotate} 0.6s linear infinite;
  }
`;

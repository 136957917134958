import { Button, ContentContainer } from "~/ui";

import React from "react";

export default function HomePage() {
  return (
    <main style={{ flexGrow: 1, paddingBottom: "6rem" }}>
      <ContentContainer mt={5}>
        <h1>We Have Moved!</h1>
        <p>
          All our course materials have been moved to{" "}
          <a href="https://kynacademy.com/?ref=reader.codaisseur.com">Kyna</a>.
          Reach out to them via <strong>support@kynacademy.com</strong> if you
          want to regain access to your (old) course materials. Be sure to
          mention in your email: 1) Your name, 2) your Codaisseur class number,
          and 3) the email address you used to log into your Reader account.
        </p>
        <p>
          <strong>
            <a href="https://kynacademy.com/?ref=reader.codaisseur.com">
              <Button>Go to Kyna</Button>
            </a>{" "}
            to see your courses.
          </strong>
        </p>
      </ContentContainer>
    </main>
  );
}

import React, { useState, useCallback } from "react";
import { ThemeProvider, createGlobalStyle, useTheme } from "styled-components";
import useNavEffect from "~/util/useNavEffect";

const baseColors = {
  white: "#fff",
  gray100: "#f0f2f4",
  gray200: "#e3e7ea",
  gray300: "#d6dbe0",
  gray400: "#bdc4cb",
  gray500: "#8c959d",
  gray600: "#5a626a",
  gray700: "#3e454b",
  gray800: "#2a2f34",
  gray900: "#101214",
  black: "#000",

  red: "#c1272d",
  blue: "#0088fa",
  indigo: "#6610f2",
  yellow: "#f6b822",
  darkgray: "#495057",
  darkblue: "#005296",
  purple: "#ba4ca1",
  pink: "#ba4ca1",
  orange: "#ff7043",
  green: "#2a7e5a",
  lightgreen: "#57ad5b",
  teal: "#26a69a",
  cyan: "#17a2b8",
  brown: "#6d544b",
};

export type ReaderTheme = {
  // basic colors
  baseColors: {
    white: string;
    gray100: string;
    gray200: string;
    gray300: string;
    gray400: string;
    gray500: string;
    gray600: string;
    gray700: string;
    gray800: string;
    gray900: string;
    black: string;

    red: string;
    blue: string;
    indigo: string;
    yellow: string;
    darkgray: string;
    darkblue: string;
    purple: string;
    pink: string;
    orange: string;
    green: string;
    lightgreen: string;
    teal: string;
    cyan: string;
    brown: string;
  };

  // derived, hooks into styled-system `color` prop
  colors: {
    white: string;
    black: string;
    primary: string;
    background: string;
    backgroundAlt: string;
    backgroundHover: string;
    backgroundFocus: string;
    text: string;
    muted: string;
  };

  // hooks into the styled-system `fontFamily` prop
  fonts: {
    sans: string;
    serif: string;
    mono: string;
    heading: string;
  };

  borderColor: [string, string, string];
  boxShadow: string;

  space: [number, number, number, number, number, number, number, number, number];
  fontSizes: [number, number, number, number, number, number, number];

  breakpoints: [string, string, string, string] & {
    sm: string;
    md: string;
    lg: string;
    xl: string;
  };

  // other
  borderRadius: [number, number, number, number];
};

export type ReaderThemeAPI = ReaderTheme & {
  darkMode: boolean;
  toggleDarkMode: () => void;

  navOpen: 0 | 1 | 2;
  set_navOpen: React.Dispatch<React.SetStateAction<0 | 1 | 2>>;

  showStudentTracker: 0 | 1 | 2;
  rotateShowStudentTracker: () => void;
};

const breakpoints: [string, string, string, string] = ["40em", "52em", "64em", "80em"];

const space: ReaderTheme["space"] = [0, 4.5, 9, 18, 28, 34, 64, 128, 256];

const common: Pick<
  ReaderTheme,
  "borderRadius" | "fonts" | "space" | "breakpoints" | "fontSizes"
> = {
  borderRadius: [0, 2, 4, 8],
  fonts: {
    // just added Open Sans to the start of the "system default stack"
    sans: `"Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`,
    serif: `"Libre Baskerville", serif`,
    // just added Roboto Mono to the start of the "system default stack"
    mono: `"Roboto Mono", SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace`,
    heading: `"Roboto Slab", serif`,
  },
  space,
  fontSizes: [12, 14, 16, 20, 24, 32, 44],
  breakpoints: Object.assign(breakpoints, {
    sm: breakpoints[0],
    md: breakpoints[1],
    lg: breakpoints[2],
    xl: breakpoints[3],
  }),
};

const lightModeTheme: ReaderTheme = {
  ...common,
  baseColors,
  colors: {
    black: baseColors.black,
    white: baseColors.white,
    primary: baseColors.red,
    background: baseColors.white,
    backgroundAlt: baseColors.white,
    backgroundHover: baseColors.gray100,
    backgroundFocus: baseColors.gray200,
    text: baseColors.gray900,
    muted: baseColors.gray600,
  },
  borderColor: [baseColors.gray300, baseColors.gray500, baseColors.gray700],
  boxShadow: `1px 2px 1rem rgba(0, 0, 0, .2)`,
};

const darkModeTheme: ReaderTheme = {
  ...common,
  baseColors,
  colors: {
    black: baseColors.white,
    white: baseColors.black,
    primary: baseColors.red,
    background: "#1c1e21",
    backgroundAlt: "#1e2225",
    backgroundHover: baseColors.gray800,
    backgroundFocus: baseColors.gray700,
    text: baseColors.gray100,
    muted: baseColors.gray500,
  },
  borderColor: [baseColors.gray700, baseColors.gray500, baseColors.gray300],
  boxShadow: `1px 2px 1rem rgba(0, 0, 0, .2)`,
};

export function ReaderThemeProvider({ children = null }: { children: React.ReactNode }) {
  // It's not the best separation of concerns, but it's definitely pragmatic,
  //  I'm just storing a number of global layout-related settings here

  // DARK MODE
  const [darkMode, set_darkMode] = useState(Boolean(localStorage.DARK_MODE));
  const theme = darkMode ? darkModeTheme : lightModeTheme;

  const toggleDarkMode = useCallback(() => {
    set_darkMode(b => {
      localStorage.DARK_MODE = !b ? "YES" : "";
      return !b;
    });
  }, []);

  // NAV BAR

  const [navOpen, _set_navOpen] = useState<0 | 1 | 2>(() => {
    const stored = localStorage.NAV;
    if (["0", "1", "2"].includes(stored)) {
      return parseInt(stored) as 0 | 1 | 2;
    } else {
      return 2;
    }
  });
  // 0 = closed
  // 1 = temporarily open
  // 2 = fixed open
  const set_navOpen = useCallback((p: React.SetStateAction<0 | 1 | 2>): void => {
    const s = typeof p === "number" ? () => p : p;
    _set_navOpen(v => {
      const v2 = s(v);
      localStorage.NAV = v2 === 2 ? "2" : "0";
      return v2;
    });
  }, []);

  useNavEffect(() => {
    set_navOpen(open => {
      return open === 1 ? 0 : open;
    });
  });

  // STUDENT TRACKING VIZ

  const [showStudentTracker, _set_showStudentTracker] = useState<0 | 1 | 2>((parseInt(
    localStorage.STUDENT_TRACKER
  ) || 0) as 0 | 1 | 2);
  // 0 = don't show
  // 1 = show status bar
  // 2 = show status bar + names of students
  const rotateShowStudentTracker = useCallback((): void => {
    _set_showStudentTracker(v => {
      const v2 = ((v + 1) % 3) as 0 | 1 | 2;
      localStorage.STUDENT_TRACKER = v2;
      return v2;
    });
  }, []);

  return (
    <ThemeProvider
      theme={{
        ...theme,
        darkMode,
        navOpen,
        set_navOpen,
        toggleDarkMode,
        showStudentTracker,
        rotateShowStudentTracker,
      }}
    >
      <GlobalStyle />
      {children}
    </ThemeProvider>
  );
}

export function useDarkMode(): [boolean, () => void] {
  const { darkMode, toggleDarkMode } = useTheme();
  return [darkMode, toggleDarkMode];
}

export function useNav(): [0 | 1 | 2, React.Dispatch<React.SetStateAction<0 | 1 | 2>>] {
  const { navOpen, set_navOpen } = useTheme();
  return [navOpen, set_navOpen];
}

const GlobalStyle = createGlobalStyle`
  html,
  body {
    margin: 0;
    padding: 0;
    font-size: 18px;
  }

  #app {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
  }

  body {
    color: ${p => p.theme.colors.text};
    background: ${p => p.theme.colors.background};
    font-family: ${p => p.theme.fonts.sans};
    line-height: 165%;
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0 0 1.5rem 0;
    line-height: 120%;

    font-family: ${p => p.theme.fonts.heading};
    color: ${p => p.theme.colors.black};
  }

  h1 {
    font-size: 2.5rem;
  }

  h2 {
    font-size: 1.7rem;
  }

  h3 {
    font-size: 1.3rem;
  }

  h4 {
    font-size: 1.05rem;
  }

  h5 {
    font-size: 1rem;
  }

  h6 {
    font-size: 1rem;
  }

  p {
    margin: 0 0 1rem 0;
  }

  .a {
    display: inline;
    border: none;
    background: none;
    font-size: inherit;
    text-decoration: underline;
    cursor: pointer;
  }

  .a,
  a {
    color: ${p => p.theme.baseColors.teal};
    font-weight: 600;
  }

  p.markdown-content,
  .markdown-content > p:last-child {
    margin-bottom: 0;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 0.25rem solid ${p =>
      p.theme.darkMode ? p.theme.baseColors.gray700 : p.theme.baseColors.gray400};
    color: ${p => p.theme.baseColors.gray500};
  }

  code {
    color: ${p => p.theme.colors.black};
    background: ${p =>
      p.theme.darkMode ? p.theme.baseColors.gray800 : p.theme.baseColors.gray100};;
    padding: 2px;
  }

  code,
  kbd,
  pre {
    font-family: ${p => p.theme.fonts.mono};
    font-size: 0.85em;
  }

  .Term {
    font-weight: bold;
    color: ${p => (p.theme.darkMode ? p.theme.baseColors.white : p.theme.baseColors.red)};

    code {
      color: ${p => (p.theme.darkMode ? p.theme.baseColors.white : p.theme.baseColors.red)};
      font-style: normal;
    }
  }

  .markdown-content {
    word-wrap: break-word;

    h1:not(:first-child),
    h2:not(:first-child),
    h3:not(:first-child),
    h4:not(:first-child),
    h5:not(:first-child),
    h6:not(:first-child) {
      margin-top: 3rem;
    }

    img {
      display: block;
      max-width: 100%;
      height: auto;
      border-style: none;
      box-sizing: content-box;
    }

    details {
      display: block;
    }

    summary {
      display: list-item;
    }

    input {
      font: inherit;
    }

    input {
      overflow: visible;
    }

    [type="checkbox"] {
      box-sizing: border-box;
      padding: 0;
    }

    * {
      box-sizing: border-box;
    }

    input {
      font-family: inherit;
      font-size: inherit;
      line-height: inherit;
    }

    hr {
      background: transparent;
      border: 0;
      border-bottom: 2px solid #dfe2e5;
      height: 0;
      overflow: hidden;

      box-sizing: content-box;
      height: 0;
      overflow: visible;

      margin: 2rem 0;
    }

    hr:before {
      content: "";
      display: table;
    }

    hr:after {
      clear: both;
      content: "";
      display: table;
    }

    .table-container {
      max-width: 100%;
      overflow-x: auto;
    }

    table {
      margin: 0 0 1rem 0;
      border-collapse: collapse;
      border-spacing: 0;
      width: auto;
      max-width: 100%;
    }

    td,
    th {
      padding: 0.25rem 0.5rem;
      border: 1px solid ${p => p.theme.borderColor[0]};
    }

    th {
      background: ${p => p.theme.colors.backgroundHover};
    }

    details summary {
      cursor: pointer;
    }

    input::-webkit-inner-spin-button,
    input::-webkit-outer-spin-button {
      -webkit-appearance: none;
      appearance: none;
      margin: 0;
    }

    kbd {
      background-color: #fafbfc;
      border: 1px solid #c6cbd1;
      border-bottom-color: #959da5;
      border-radius: 3px;
      box-shadow: inset 0 -1px 0 #959da5;
      color: #444d56;
      display: inline-block;
      font-size: 11px;
      line-height: 10px;
      padding: 3px 5px;
      vertical-align: middle;
    }
  }
`;

import {
  Box,
  IconButton,
  Image,
  Loader,
  MoonIcon,
  SearchIcon,
  SunIcon,
} from "~/ui";
import React, { useRef, useState } from "react";

import { Link } from "react-router-dom";
import logo from "~/assets/logo.svg";
import logo_dark from "~/assets/logo_dark.svg";
import logo_icon from "~/assets/logo_icon.svg";
import styled from "styled-components";
import { useDarkMode } from "~/lib/theme";

export function Toolbar() {
  const [darkMode, toggle_darkMode] = useDarkMode();

  return (
    <>
      <div style={{ height: "3rem" }} />
      <ToolbarBox role="navigation" aria-label="main navigation" id="toolbar">
        <Link className="navbar-item" to="/">
          <Image
            src={darkMode ? logo_dark : logo}
            width="143"
            height="28"
            display={["none", "block"]}
          />
          <Image
            src={logo_icon}
            width="36"
            height="28"
            display={["block", "none"]}
          />
        </Link>

        <div style={{ flexGrow: 4 }} />
        <Link to="/help" style={{ marginRight: "1rem" }}>
          Help!
        </Link>

        <DarkModeToggler
          onClick={toggle_darkMode}
          key={darkMode ? "turnLight" : "turnDark"}
          mr={-2}
        >
          {darkMode ? <SunIcon /> : <MoonIcon />}
        </DarkModeToggler>
      </ToolbarBox>
    </>
  );
}

const DarkModeToggler = styled(IconButton)`
  color: ${(p) =>
    p.theme.darkMode ? p.theme.baseColors.yellow : p.theme.baseColors.indigo};
`;

const ToolbarBox = styled.nav`
  position: relative;
  z-index: 1030;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 3rem;

  padding: 0 1.5rem;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  background: ${(p) => p.theme.colors.backgroundAlt};
  border-bottom: 1px solid ${(p) => p.theme.borderColor[0]};
`;

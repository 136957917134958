import "react-dates/initialize";
import "./style/codemirror_theme.scss";

import { ApolloProvider } from "@apollo/react-hooks";
import App from "~/App";
import React from "react";
import { ReaderThemeProvider } from "~/lib/theme";
import { BrowserRouter as Router } from "react-router-dom";
import { render } from "react-dom";

render(
  <Router>
    <ReaderThemeProvider>
      <App />
    </ReaderThemeProvider>
  </Router>,
  document.getElementById("app")
);

if ("hot" in module) {
  (module as any).hot.accept();
}

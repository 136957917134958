import React from "react";
import { stripIndent } from "common-tags";

import Markdown from "~/components/Markdown";

interface Props {
  children?: React.ReactNode;
}

export function Markdown_TheContentComponent({ children }: Props) {
  if (typeof children === "string") {
    const source = stripIndent(children);
    return <Markdown inline={source.indexOf("\n") < 0} source={source} contentComponents={false} />;
  } else {
    return <>{children}</>;
  }
}

import React, { useMemo, useState } from "react";
import styled, { useTheme, keyframes } from "styled-components";
import { SwitchTransition } from "react-transition-group";
import Joi from "@hapi/joi";

import shuffleArray from "~/util/shuffleArray";
import useExerciseResponder from "~/lib/useExerciseResponder";

import defineContentComponent, { Joi_ReactNode } from "./defineContentComponent";

import { Box, IconButton } from "~/ui";
import Markdown from "~/components/Markdown";
import { IoMdCheckmark, IoMdClose, IoMdRefresh } from "react-icons/io";
import Timeout from "await-timeout";

type Direction = "forward" | "backward" | "both";

type Props = {
  id: string;
  title?: React.ReactNode;
  direction?: Direction;
  shuffle?: boolean;
  cards: Card[];
};

type Card = {
  a: React.ReactNode;
  b: React.ReactNode;
};

function neg(side: "a" | "b") {
  return side === "a" ? "b" : "a";
}

function FlashCards({ id, title, direction = "both", shuffle = true, cards }: Props) {
  const theme = useTheme();

  const makeQueue = () => {
    let queue: Array<{
      index: number;
      up: "a" | "b";
      retry?: boolean;
      flipped?: boolean;
    }> = [
      ...(direction === "forward" || direction === "both"
        ? cards.map((_, index) => {
            return {
              index,
              up: "a" as "a",
            };
          })
        : []),
      ...(direction === "backward" || direction === "both"
        ? cards.map((_, index) => {
            return {
              index,
              up: "b" as "b",
            };
          })
        : []),
    ];

    if (shuffle) {
      queue = shuffleArray(queue);
    }

    return queue;
  };

  const [queue, set_queue] = useState(makeQueue);

  const [score, set_score] = useState(0);
  const [at, set_at] = useState(0);

  const [lastAction, set_lastAction] = useState("init");

  const startOver = () => {
    set_lastAction("startOver");
    set_queue(makeQueue());
    set_at(0);
    set_score(0);
  };

  const goToNext = () => {
    set_at(at => at + 1);
  };

  const gotIt = () => {
    set_lastAction("gotIt");
    if (!queue[at].retry) {
      set_score(score => score + 1);
    }
    goToNext();
  };

  const tryAgainLater = () => {
    set_lastAction("tryAgainLater");
    set_queue(q => [...q, { ...queue[at], retry: true }]);
    goToNext();
  };

  const flip = () => {
    set_queue(queue => {
      const copy = [...queue];
      copy[at] = {
        ...queue[at],
        flipped: !queue[at].flipped,
      };
      return copy;
    });
  };

  // Using `{ index?: number }` instead of just number, to deal with
  //  JSON conversion edge-cases, and to support the "non-answer" `{}`.
  // const [currentAnswer, respond] = useExerciseResponder<{ index?: number }>(id, {});

  // const answered = !currentAnswer.loading && typeof currentAnswer.value.index != "undefined";

  // const passed = answered && correctIndex === currentAnswer.value.index;
  // const failed = answered && correctIndex !== currentAnswer.value.index;

  // const submitResponse = (index: number) => {
  //   if (currentAnswer.loading) {
  //     return;
  //   }

  //   const passed = correctIndex === index;
  //   respond(
  //     { index },
  //     {
  //       passed,
  //       meta: learning_goals ? JSON.stringify({ learning_goals }) : undefined,
  //     }
  //   );
  // };

  // const question_node =
  //   typeof question === "string" ? <Markdown inline source={question} /> : question;

  if (queue.length === 0) {
    return null;
  }

  return (
    <Box mt={4} mb={2}>
      <h4>🎓 {title ? <InlineMarkdown>{title}</InlineMarkdown> : "Flash card test"}</h4>
      <CardContainer>
        <Actions>
          <Action key={at} onClick={startOver} style={{ textAlign: "right" }}>
            <IoMdRefresh
              style={{
                verticalAlign: "text-bottom",
                transform: "scale(-1, 1)",
                fontSize: "1.5rem",
                color: theme.colors.text,
              }}
            />
            <Label>Start over</Label>
          </Action>
        </Actions>
        <CardSpace>
          {queue.map((item, i) => {
            const active = i === at;

            return (
              <CardScene
                key={item.index + "_" + item.up + "_" + i}
                style={{
                  transition: lastAction === "startOver" ? "none" : "all 0.5s ease",
                  opacity: active ? 1 : 0,
                  transform: `translate(${active ? 0 : 1.5 * Math.sign(i - at)}rem, 0)`,
                }}
              >
                <CardObject
                  style={{ transform: `rotateY(${item.flipped ? 180 : 0}deg)` }}
                  onClick={flip}
                >
                  <CardFace side={item.up}>
                    <InlineMarkdown>{cards[item.index][item.up]}</InlineMarkdown>
                  </CardFace>
                  <CardFace side={neg(item.up)} style={{ transform: "rotateY(180deg)" }}>
                    <InlineMarkdown>{cards[item.index][neg(item.up)]}</InlineMarkdown>
                  </CardFace>
                </CardObject>
              </CardScene>
            );
          })}
          {at === queue.length && (
            <CardScene
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p>Completed!</p>
              <p>
                Score: {score} / {queue.filter(i => !i.retry).length}
              </p>
            </CardScene>
          )}
        </CardSpace>
        <Actions>
          {at < queue.length && (
            <>
              <Action key={at + "_gotIt"} onClick={gotIt}>
                <span style={{ color: theme.baseColors.green }}>
                  <IoMdCheckmark style={{ verticalAlign: "text-bottom", fontSize: "1.5rem" }} />{" "}
                  <strong>{score}</strong>
                </span>
                <Label>Got it!</Label>
              </Action>
              <Action key={at + "_tryAgainLater"} onClick={tryAgainLater}>
                <IoMdClose
                  style={{
                    verticalAlign: "text-bottom",
                    fontSize: "1.5rem",
                    color: theme.baseColors.red,
                  }}
                />
                <Label>Try again later</Label>
              </Action>
            </>
          )}
        </Actions>
      </CardContainer>
      <p style={{ textAlign: "center" }}>
        <Label>
          Card {Math.min(at + 1, queue.length)} / {queue.length}
        </Label>
      </p>
    </Box>
  );
}

function InlineMarkdown({ children }: { children?: React.ReactNode | string }) {
  return typeof children === "string" ? <Markdown inline source={children} /> : <>{children}</>;
}

const Res = styled.span<{ state: "passed" | "failed" }>`
  font-weight: bold;
  margin-left: 1rem;
  color: ${p => (p.state === "passed" ? p.theme.baseColors.green : p.theme.baseColors.red)};
`;

const CardContainer = styled.div`
  margin: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Actions = styled.div`
  width: 8rem;
`;

const Action = styled.button`
  all: inherit;

  margin: 0.5rem 0;
  padding: 0.5rem;
  display: block;
  width: 100%;
  border: 2px solid transparent;
  border-radius: 6px;
  outline: none;
  background: none;
  line-height: 1.5;

  outline: none;
  cursor: pointer;

  &:focus {
    outline: none;
    border: 2px solid ${p => (p.theme.darkMode ? "rgba(255, 255, 255, 0.1)" : "rgba(0, 0, 0, 0.1)")};
  }
`;

const Label = styled.div`
  color: ${p => p.theme.colors.muted};
  font-size: 0.85em;
`;

const CardSpace = styled.div`
  margin: 0 1.5rem;
  width: 20rem;
  height: 14rem;
  position: relative;
`;

const CardScene = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  perspective: 1000px;
`;

const CardObject = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  transition: transform 0.75s;
  transform-style: preserve-3d;

  cursor: pointer;
  user-select: none;
`;

const CardFace = styled.div<{ side: "a" | "b" }>`
  position: absolute;
  height: 100%;
  width: 100%;
  backface-visibility: hidden;

  display: flex;
  justify-content: center;
  align-items: center;

  background: ${p =>
    p.side === "a" ? p.theme.colors.backgroundAlt : p.theme.colors.backgroundHover};
  border: 2px solid ${p => (p.side === "a" ? p.theme.colors.text : p.theme.baseColors.blue)};
  border-radius: 6px;
  padding: 1.25rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
`;

export default defineContentComponent(FlashCards, {
  schema: Joi.object({
    id: Joi.string().required(),
    title: Joi_ReactNode,
    direction: Joi.valid("forward", "backward", "both"),
    shuffle: Joi.bool(),
    cards: Joi.array()
      .items(
        Joi.object({
          a: Joi_ReactNode.required(),
          b: Joi_ReactNode.required(),
        })
      )
      .required(),
  }),
});

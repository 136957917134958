import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";

import theme from "~/lib/theme";

class ErrorBoundary extends React.Component<RouteComponentProps> {
  state = { hasError: false };

  static getDerivedStateFromError(error: Error) {
    console.warn("Marrkdown rendering error occurred!", error);
    return { hasError: true };
  }

  componentDidMount() {
    const { history } = this.props;

    history.listen((location, action) => {
      if (this.state.hasError) {
        this.setState({
          hasError: false
        });
      }
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div
          className="pl-3"
          style={{ borderLeft: `5px solid ${theme.colors.red}` }}
        >
          <h1>Err... that's embarrassing :|</h1>
          <p>
            For some reason we couldn't render this page properly. Please do
            tell us (for example in the <code>#errors</code> Slack channel), so
            we can solve it ASAP!
          </p>
          <p className="mb-0">
            <code>{this.props.location.pathname}</code>
          </p>
        </div>
      );
    }

    return this.props.children;
  }
}

const MarkdownParseErrorBoundary = withRouter(ErrorBoundary);

export default MarkdownParseErrorBoundary;

import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { IoMdArrowDropdown } from "react-icons/io";

import { useContentContext } from "~/lib/content_context";
import { ContentMeta } from "~/api";

import { IconButton } from "./IconButton";
import { SiblingsDropdown } from "./SiblingsDropdown";
import { Box } from "./basics";

import Markdown from "~/components/Markdown";

export function Breadcrumbs() {
  const { breadcrumbs } = useContentContext();

  const [siblingsOpenPath, set_siblingsOpenPath] = useState<string>();

  return (
    <Box as="nav" mb={4} style={{ fontSize: "0.9rem" }} fontFamily="sans" aria-label="breadcrumb">
      <BreadcrumbsList>
        {breadcrumbs.map(contentMeta => {
          return (
            <Breadcrumb
              contentMeta={contentMeta}
              key={contentMeta.id}
              open={siblingsOpenPath === contentMeta.path}
              onOpen={() => set_siblingsOpenPath(contentMeta.path)}
              onClose={() => set_siblingsOpenPath(undefined)}
            />
          );
        })}
      </BreadcrumbsList>
    </Box>
  );
}

function Breadcrumb({
  contentMeta,
  open,
  onOpen,
  onClose,
}: {
  contentMeta: ContentMeta;
  open: boolean;
  onOpen: () => void;
  onClose: () => void;
}) {
  const { at, breadcrumbs } = useContentContext();

  const active = at && at.path === contentMeta.path;

  const i = breadcrumbs.findIndex(b => b.path === contentMeta.path);
  const siblings = i > 0 ? breadcrumbs[i - 1].sections : undefined;

  const title = <Markdown inline source={contentMeta.title} />;

  return (
    <BreadcrumbItem>
      {active ? <span>{title}</span> : <Link to={contentMeta.path}>{title}</Link>}
      {siblings && siblings.length > 1 && (
        <>
          <DropdownButton onClick={onOpen} style={{ marginLeft: 2, top: -1 }}>
            <IoMdArrowDropdown size="1rem" />
          </DropdownButton>
          {open && <SiblingsDropdown siblings={siblings} onClose={onClose} />}
        </>
      )}
    </BreadcrumbItem>
  );
}

export const DropdownButton = styled(IconButton)`
  display: inline-flex;
  position: relative;
  font-size: 1rem;
  vertical-align: bottom;
`;

DropdownButton.defaultProps = {
  color: "muted",
  p: 1,
  mr: -1,
};

const BreadcrumbsList = styled.ol`
  margin: 0;
  padding: 0.75rem 0;

  display: flex;
  flex-wrap: wrap;
  list-style: none;
  background-color: none;
  border-radius: 0.25rem;
`;

const BreadcrumbItem = styled.li`
  white-space: nowrap;
  font-weight: 600;

  &:not(:first-child) {
    padding-left: ${p => p.theme.space[2]}px;

    &::before {
      display: inline-block;
      padding-right: ${p => p.theme.space[2]}px;
      color: ${p => p.theme.colors.muted};
      font-weight: normal;
      content: "/";
    }
  }

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

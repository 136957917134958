import React, { useMemo } from "react";
import Joi from "@hapi/joi";

export const Joi_ReactNode = Joi.alternatives().try(
  Joi.number(),
  Joi.string(),
  Joi.boolean(),
  Joi.object()
    .keys({
      type: Joi.alternatives()
        .try(Joi.string(), Joi.function())
        .required(),
      props: Joi.object().required()
    })
    .pattern(/./, Joi.any()),
  Joi.array().items(Joi.ref("..."))
);

interface Options {
  schema?: ReturnType<typeof Joi.object>;
}

export default function defineContentComponent(
  Component: React.ComponentType<any>,
  options: Options = {}
) {
  const { schema = Joi.object() } = options;

  return function ContentComponent(props: any) {
    const validated = useMemo(() => {
      const { error } = schema.pattern(/./, Joi.any()).validate(props);
      if (error) {
        return {
          error
        };
      }
      return { props };
    }, []);

    if (validated.props) {
      return <Component {...validated.props} />;
    } else {
      console.error(validated);
      return <div>Content componenent could not be rendered</div>;
    }
  };
}

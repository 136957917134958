import gql from "graphql-tag";

export interface CourseInfo {
  id: string;
  name: string;
  description: string;
  pathToContent: string;
  studentsCount: number;

  startDate: Date;
  endDate: Date;
}

export const CourseInfo_fragment = gql`
  fragment CourseInfo on Course {
    id
    name
    description
    pathToContent
    studentsCount

    startDate
    endDate
  }
`;

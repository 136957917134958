import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { useContentContext, LEVEL_DAY } from "~/lib/content_context";
import { ContentMeta } from "~/api";

import { ArrowLeftIcon, ArrowRightIcon } from "./icons";
import { Box } from "./basics";
import Markdown from "~/components/Markdown";

export function ContentEndNav() {
  const { at, breadcrumbs } = useContentContext();

  const i = breadcrumbs.findIndex(b => b.path === at.path);

  if (i < LEVEL_DAY) {
    // This component is only meant for within-day navigation
    return null;
  }

  const siblings = breadcrumbs[i - 1].sections;
  if (!siblings) {
    // Should not be possible
    return null;
  }
  const j = siblings.findIndex(b => b.path === at.path);

  let next: null | Pick<ContentMeta, "id" | "title" | "description" | "path"> = null;

  if (at.sections && at.sections.length > 0) {
    // dive deeper
    next = at.sections[0];
  } else if (siblings[j + 1]) {
    // go to next sibling
    next = siblings[j + 1];
  } else if (i >= 3) {
    // (don't escape bootcamp)
    // go up to parent, then its next sibling
    const aunts = breadcrumbs[i - 2].sections || [];
    const parentIndex = aunts.findIndex(b => b.path === breadcrumbs[i - 1].path);
    if (parentIndex < 0) {
      return null;
    } else if (aunts[parentIndex + 1]) {
      next = aunts[parentIndex + 1];
    } else if (i >= 4) {
      // (don't escape bootcamp)
      // go up two parents, then its next sibling
      const greatAunts = breadcrumbs[i - 3].sections || [];
      const grantParentIndex = greatAunts.findIndex(b => b.path === breadcrumbs[i - 2].path);
      if (grantParentIndex < 0) {
        return null;
      } else if (greatAunts[grantParentIndex + 1]) {
        next = greatAunts[grantParentIndex + 1];
      }
    }
  }

  let prev: null | Pick<ContentMeta, "id" | "title" | "description" | "path"> = null;

  if (j > 0 && siblings[j - 1]) {
    // TS doesn't know, but this can indeed happen, haha
    const prevSibling = siblings[j - 1] as ContentMeta;
    if (prevSibling.sections && prevSibling.sections.length > 0) {
      // down one level: last child of previous sibling
      prev = prevSibling.sections.slice(-1)[0];
    } else if (i >= LEVEL_DAY) {
      // same level, back one sibling
      prev = prevSibling;
    }
  } else if (i > LEVEL_DAY) {
    // up one level
    prev = breadcrumbs[i - 1];
  }

  return (
    <Box display="flex" mt={5} key={at.path}>
      <Box flex={1} style={{ width: "50%", marginRight: 8 }}>
        {prev && (
          <NavLink
            to={prev.path}
            // pr={2}
            // style={{ paddingLeft: "2rem", textDecoration: "none" }}
          >
            <Box color="muted" style={{ width: "2.5rem", textAlign: "center" }}>
              <ArrowLeftIcon size="1.5rem" />
            </Box>
            <Box flex={1}>
              <Box
                as="small"
                display="block"
                fontFamily="heading"
                color="muted"
                style={{ textTransform: "uppercase", fontWeight: "normal" }}
              >
                Previous
              </Box>
              <span>
                <Markdown inline source={prev.title} />
              </span>
            </Box>
          </NavLink>
        )}
      </Box>
      <Box flex={1} style={{ width: "50%", marginLeft: 8, textAlign: "right" }}>
        {next && (
          <NavLink
            to={next.path}
            // pl={2}
            // style={{ paddingRight: "2rem", textDecoration: "none" }}
          >
            <Box flex={1}>
              <Box
                as="small"
                display="block"
                fontFamily="heading"
                color="muted"
                style={{ textTransform: "uppercase", fontWeight: "normal" }}
              >
                Next
              </Box>
              <span>
                <Markdown inline source={next.title} />
              </span>
            </Box>
            <Box color="muted" style={{ width: "2.5rem", textAlign: "center" }}>
              <ArrowRightIcon size="1.5rem" />
            </Box>
          </NavLink>
        )}
      </Box>
    </Box>
  );
}

const NavLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;

  padding: 1rem 0;

  border-radius: 8px;
  border: 2px solid transparent;

  &:hover {
    border: 2px solid ${p => p.theme.borderColor[0]};
  }

  &:focus {
    border: 2px solid ${p => p.theme.borderColor[0]};
  }

  &:active {
    background: ${p => p.theme.colors.backgroundHover};
  }
`;

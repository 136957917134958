import React, { useMemo } from "react";
import styled from "styled-components";
import Joi from "@hapi/joi";

import shuffleArray from "~/util/shuffleArray";
import useExerciseResponder from "~/lib/useExerciseResponder";

import defineContentComponent, {
  Joi_ReactNode
} from "./defineContentComponent";

import { Box } from "~/ui";
import Markdown from "~/components/Markdown";

interface Props {
  id: string;
  question: React.ReactNode;
  content: React.ReactNode;
  options: React.ReactNode[];
  shuffle?: boolean;
  correctIndex?: number;
  learning_goals?: string[];
}

function MultiChoice({
  id,
  question,
  content,
  options,
  shuffle = false,
  correctIndex = 0,
  learning_goals
}: Props) {
  // only shuffle once
  const indices = useMemo(() => {
    if (shuffle) {
      return shuffleArray(options.map((_, i) => i));
    } else {
      return options.map((_, i) => i);
    }
  }, [options, shuffle]);

  // Using `{ index?: number }` instead of just number, to deal with
  //  JSON conversion edge-cases, and to support the "non-answer" `{}`.
  const [currentAnswer, respond] = useExerciseResponder<{ index?: number }>(
    id,
    {}
  );

  const answered =
    !currentAnswer.loading && typeof currentAnswer.value.index != "undefined";

  const passed = answered && correctIndex === currentAnswer.value.index;
  const failed = answered && correctIndex !== currentAnswer.value.index;

  const submitResponse = (index: number) => {
    if (currentAnswer.loading) {
      return;
    }

    const passed = correctIndex === index;
    respond(
      { index },
      {
        passed,
        meta: learning_goals ? JSON.stringify({ learning_goals }) : undefined
      }
    );
  };

  const question_node =
    typeof question === "string" ? (
      <Markdown inline source={question} />
    ) : (
      question
    );

  return (
    <Box mt={4} mb={2}>
      <h4>
        🎓 Question {passed && <Res state="passed">CORRECT!</Res>}
        {failed && <Res state="failed">INCORRECT :(</Res>}
      </h4>
      <Box as="p" mb={2} style={{ fontWeight: 600 }}>
        {question_node}
      </Box>
      {content && <div className="QuestionContent">{content}</div>}
      <Row>
        {indices.map(i => {
          const option_id = `mc_${id}_${i}`;
          const selected = currentAnswer.value.index === i;
          const option = options[i];
          const option_node =
            typeof option === "string" ? (
              <Markdown inline source={option} />
            ) : (
              option
            );
          return (
            <Col
              key={i}
              // className={cx("Option p-1", selected && "selected")}
              style={{
                flex: "50% 0 0",
                fontWeight: selected ? 600 : "inherit"
              }}
            >
              <div className="form-check">
                <input
                  style={{ cursor: "pointer" }}
                  className="form-check-input"
                  type="radio"
                  id={option_id}
                  checked={selected}
                  onChange={e => {
                    if (e.target.checked) {
                      submitResponse(i);
                    }
                  }}
                />
                <label
                  style={{ cursor: "pointer" }}
                  className="form-check-label"
                  htmlFor={option_id}
                >
                  {option_node}
                </label>
              </div>
            </Col>
          );
        })}
      </Row>
    </Box>
  );
}

const Res = styled.span<{ state: "passed" | "failed" }>`
  font-weight: bold;
  margin-left: 1rem;
  color: ${p =>
    p.state === "passed" ? p.theme.baseColors.green : p.theme.baseColors.red};
`;

const Row = styled(Box)`
  margin: 0 -0.5rem;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
`;

const Col = styled(Box).attrs({
  flex: ["0 0 100%", "0 0 50%", "0 0 50%"]
})`
  padding: 0 0.5rem;
  box-sizing: border-box;
  display: flex;
  align-items: stretch;
  margin-bottom: 1rem;
`;

export default defineContentComponent(MultiChoice, {
  schema: Joi.object({
    id: Joi.string().required(),
    question: Joi_ReactNode.required(),
    content: Joi_ReactNode,
    options: Joi.array()
      .items(Joi_ReactNode)
      .required(),
    shuffle: Joi.bool(),
    correctIndex: Joi.number().integer(),
    learning_goals: Joi.array().items(Joi.string())
  })
});

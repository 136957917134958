import React, { useRef } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import useOnClickOutside from "use-onclickoutside";

import { useContentContext } from "~/lib/content_context";

import { Box } from "./basics";
import { UnstyledList } from "./basics";

export function SiblingsDropdown({
  siblings,
  onClose,
}: {
  siblings: Array<{ id: string; title: string; path: string }>;
  onClose: () => void;
}) {
  const { at } = useContentContext();

  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => {
    onClose();
  });

  return (
    <Box position="relative" style={{ zIndex: 110 }}>
      <DropdownBox ref={ref}>
        <UnstyledList>
          {siblings.map(sibling => {
            const active =
              at && (at.path === sibling.path || at.path.startsWith(sibling.path + "/"));

            return (
              <li key={sibling.id}>
                <SiblingLink to={sibling.path} onClick={onClose} className={active ? "active" : ""}>
                  {sibling.title}
                </SiblingLink>
              </li>
            );
          })}
        </UnstyledList>
      </DropdownBox>
    </Box>
  );
}

const DropdownBox = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 150;
  background: ${p => p.theme.colors.backgroundAlt};
  border: 1px solid ${p => p.theme.borderColor[0]};
  box-shadow: ${p => p.theme.boxShadow};
`;

const SiblingLink = styled(Link)`
  display: block;
  padding: 0.25rem 0.75rem;
  color: ${p => p.theme.colors.text};
  font-weight: normal;

  &,
  &:hover {
    text-decoration: none !important;
  }

  transition: background-color 0.2s ease;

  background: transparent;

  &:hover {
    background: ${p => p.theme.colors.backgroundHover};
  }

  &:focus,
  &.active,
  &:active {
    background: ${p => p.theme.colors.backgroundFocus};
  }

  &.active {
    font-weight: 600;
  }
`;

import React from "react";
import styled, { useTheme } from "styled-components";
import { Box, BoxProps } from "~/ui";

type Props = BoxProps & {
  tag: string;
  children?: React.ReactNode;
};

export function Tag({ tag, children, style, ...rest }: Props) {
  const coloring = useTagColoring(tag);

  return (
    // @ts-ignore
    <TagBox {...rest} style={{ ...style, ...coloring }}>
      {children || tag}
    </TagBox>
  );
}

const TagBox = styled(Box).attrs({
  as: "span",
  py: 0,
  px: 2,
})<BoxProps>`
  display: inline-block;
  font-weight: 600;
  font-size: 0.8em;
  border-radius: 999px;
  user-select: none;
`;

export function useTagColoring(tag: string) {
  const theme = useTheme();

  switch (true) {
    case /javascript|typescript|ideation|json/i.test(tag):
      return {
        backgroundColor: theme.baseColors.yellow,
        color: theme.baseColors.black,
      };

    case /html|react|research|spa|composition|prop|state|component|dom|lifecycle/i.test(tag):
      return {
        backgroundColor: theme.baseColors.blue,
        color: theme.baseColors.white,
      };

    case /ruby|ajax|databases|visual|design|mpa/i.test(tag):
      return {
        backgroundColor: theme.baseColors.red,
        color: theme.baseColors.white,
      };

    case /git|http|apis|deploy|methods|controller|api/i.test(tag):
      return {
        backgroundColor: theme.baseColors.black,
        color: theme.baseColors.white,
      };

    case /express|websockets|management/i.test(tag):
      return {
        backgroundColor: theme.baseColors.brown,
        color: theme.baseColors.white,
      };

    case /redux|test|tdd|mvc/i.test(tag):
      return {
        backgroundColor: theme.baseColors.purple,
        color: theme.baseColors.white,
      };

    case /jquery|docker|postgres/i.test(tag):
      return {
        backgroundColor: theme.baseColors.teal,
        color: theme.baseColors.white,
      };

    case /node|mongo|fundamentals|interface|full stack/i.test(tag):
      return {
        backgroundColor: theme.baseColors.lightgreen,
        color: theme.baseColors.black,
      };

    case /css|graphql|bdd|view/i.test(tag):
      return {
        backgroundColor: theme.baseColors.pink,
        color: theme.baseColors.white,
      };

    case /sql|integration|ci|prototyp|database|sequelize|model/i.test(tag):
      return {
        backgroundColor: theme.baseColors.orange,
        color: theme.baseColors.white,
      };

    default:
      return {
        backgroundColor: theme.baseColors.gray600,
        color: theme.baseColors.white,
      };
  }
}

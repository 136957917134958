import React from "react";
import styled, { css } from "styled-components";
import cx from "classnames";
import Highlight, { defaultProps, Language } from "prism-react-renderer";

import { codaisseurSyntaxThemes } from "~/lib/syntax_themes";
import { useDarkMode } from "~/lib/theme";

interface Props {
  language?: string;
  value: string;
}

const RENAMES: {
  [lang: string]: string;
} = {
  css: "scss",

  // For simplicity (in the scss files and for theming)
  zsh: "bash",
  sh: "bash",
  shell: "bash",

  // Going for the 'maximal' syntax possible,
  //  to avoid jsx and type annotations accidentally
  //  not being highlighted
  javascript: "tsx",
  JavaScript: "tsx",
  js: "tsx",
  jsx: "tsx",
  ts: "tsx",
};

export default function CodeBlock({ language = "", value }: Props) {
  const [darkMode] = useDarkMode();

  language = language || "";

  const collapse = Boolean(language.match(/\bcollapse/));
  const diff = Boolean(language.match(/^diff[-:]/));
  const yes_linenos = Boolean(language.match(/\blinenos/));
  const no_linenos = Boolean(language.match(/\bnolinenos/));

  language = language.replace(/^diff[-:]/, "");
  language = language.split(/\b/)[0];
  language = RENAMES[language] || language;

  const linenos = yes_linenos
    ? true
    : no_linenos
    ? false
    : ["scss", "html", "tsx"].includes(language);

  const theme = {
    ...codaisseurSyntaxThemes[language === "bash" ? "shell" : "default"][
      darkMode ? "dark" : "light"
    ],
  };

  const diff_ops: string[] = [];

  const code = diff
    ? value
        .split(/\r?\n/)
        .map((line, i) => {
          diff_ops[i] = (line[0] || "").trim();
          return line.slice(1);
        })
        .join("\n")
    : value;

  return (
    <CodeBlockBox
      language={language}
      collapse={collapse}
      style={{ backgroundColor: theme.plain.backgroundColor }}
    >
      <Pad>
        {linenos && (
          <Linenos>
            {code
              .split("\n")
              .map((_, n) => n + 1)
              .join("\n")}
          </Linenos>
        )}
        {diff && <Diffs>{diff_ops.join("\n")}</Diffs>}
        <Highlight {...defaultProps} code={code} language={language as Language} theme={theme}>
          {({ className, style, tokens, getLineProps, getTokenProps }) => {
            return (
              <pre className={className} style={style}>
                {tokens.map((line, i) => {
                  const lineProps = getLineProps({ line, key: i });
                  if (diff) {
                    lineProps.className += ` diffed`;
                    lineProps["data-diff"] = diff_ops[i];
                  }

                  return (
                    <div {...lineProps}>
                      {line.map((token, j) => {
                        const props = getTokenProps({ token, key: j });
                        // The "prompt" of a command line is quite important
                        //  to delineate for beginners, and if parsed at all,
                        //  shows up as an "operator" token.
                        if (
                          j === 0 &&
                          language === "bash" &&
                          (token.types.includes("operator") || token.content.match(/^[$>~][ ]*$/))
                        ) {
                          token.types.push("prompt");
                          props.className += " prompt";
                        }
                        return <span {...props} />;
                      })}
                      <br />
                    </div>
                  );
                })}
              </pre>
            );
          }}
        </Highlight>
      </Pad>
    </CodeBlockBox>
  );
}

const Linenos = styled.pre`
  text-align: right;
  padding-right: 0.75rem !important;
  user-select: none;

  font-size: 0.7rem !important;
  color: ${p => p.theme.colors.muted};
`;

const Diffs = styled.pre`
  user-select: none;
`;

const Pad = styled.div`
  padding: 0.5rem 0.75rem;
  display: flex;
  overflow-x: auto;
`;

const CodeBlockBox = styled.div<{ language: string; collapse?: boolean }>`
  overflow-x: auto;

  box-sizing: border-box;
  margin-bottom: 1rem;
  padding: 0;
  border: 1px solid
    ${p => (p.theme.darkMode ? p.theme.baseColors.gray800 : p.theme.baseColors.gray200)};
  border-left: 3px solid
    ${p => (p.theme.darkMode ? p.theme.baseColors.gray100 : p.theme.baseColors.gray700)};

  ${p =>
    p.language === "bash" &&
    css`
      border-color: ${p =>
        p.theme.darkMode ? p.theme.baseColors.gray700 : p.theme.baseColors.gray900};
      border-left-color: ${p => p.theme.baseColors.yellow};
      /* box-shadow: $box-shadow-sm; */

      .prompt {
        color: ${p => p.theme.baseColors.yellow} !important;
      }
    `}

  ${p =>
    p.collapse &&
    css`
      max-height: 20rem;

      background: /* Shadow covers */ linear-gradient(
          ${p => p.theme.colors.backgroundHover} 30%,
          rgba(255, 255, 255, 0)
        ),
        linear-gradient(rgba(255, 255, 255, 0), ${p => p.theme.colors.backgroundHover} 70%) 0 100%,
        /* Shadows */ radial-gradient(50% 0, farthest-side, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)),
        radial-gradient(50% 100%, farthest-side, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
      background: /* Shadow covers */ linear-gradient(
          ${p => p.theme.colors.backgroundHover} 30%,
          rgba(255, 255, 255, 0)
        ),
        linear-gradient(rgba(255, 255, 255, 0), ${p => p.theme.colors.backgroundHover} 70%) 0 100%,
        /* Shadows */ radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)),
        radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
      background-repeat: no-repeat;
      background-color: ${p => p.theme.colors.background};
      background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
      /* Opera doesn't support this in the shorthand */
      background-attachment: local, local, scroll, scroll;
    `};

  pre {
    margin: 0;
    padding: 0;
    font-size: 0.8rem;
    line-height: 1.4rem;
    background: none !important;

    &.prism-code {
      flex-grow: 1;
    }

    .token-line[data-diff="+"] {
      background: rgba(42, 126, 90, 0.2);
    }
    .token-line[data-diff="-"] {
      background: rgba(193, 39, 45, 0.2);
    }
  }
`;

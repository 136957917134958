import styled from "styled-components";
import {
  layout,
  LayoutProps,
  space,
  SpaceProps,
  position,
  PositionProps,
  compose,
} from "styled-system";

export type ImageProps = LayoutProps & SpaceProps & PositionProps & React.ComponentProps<"img">;

export const Image = styled("img")<ImageProps>(compose(layout, position, space));

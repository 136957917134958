import "react-dates/lib/css/_datepicker.css";
import "codemirror/lib/codemirror.css";

import { Route, Switch } from "react-router-dom";

import Footer from "~/components/Footer";
import Helmet from "react-helmet";
import HelpPage from "./pages/HelpPage";
import HomePage from "~/pages/HomePage";
import React from "react";
import { ScrollToTop } from "~/util/scroll_restoration";
import { Toolbar } from "~/ui";
import { hot } from "react-hot-loader";

// import "~/style/global.scss";

// import "./style/react_dates_overrides.scss"; // TODO

// import "./style/codemirror_theme.scss"; // TODO

function App() {
  return (
    <ScrollToTop>
      <Helmet titleTemplate="%s | Codaisseur Reader v4.0">
        <title>Welcome</title>
        <meta name="description" content="Your learning journey starts here!" />
      </Helmet>
      <Toolbar />
      <div
        id="wrap"
        style={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Switch>
          <Route path="/help" component={HelpPage} />
          <Route component={HomePage} />
        </Switch>
      </div>
      <Footer />
    </ScrollToTop>
  );
}

export default process.env.NODE_ENV === "development" ? hot(module)(App) : App;

import styled from "styled-components";
import {
  flex,
  FlexProps,
  layout,
  LayoutProps,
  typography,
  TypographyProps,
  color,
  ColorProps,
  space,
  SpaceProps,
  position,
  PositionProps,
  compose
} from "styled-system";

export type BoxProps = FlexProps &
  LayoutProps &
  TypographyProps &
  ColorProps &
  SpaceProps &
  PositionProps &
  React.ComponentProps<"div">;

export const Box = styled("div")<BoxProps>(
  compose(
    flex,
    layout,
    typography,
    position,
    space,
    color,
    () => `box-sizing: border-box;`
  )
);

export const UnstyledList = styled.ul<SpaceProps>`
  margin: 0;
  padding: 0;

  ${space};

  > li {
    list-style: none;
    margin: 0;
    padding: 0;
  }
`;

export const Row = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
`;

Row.defaultProps = {
  mx: -2
};

export const Col = styled(Box)`
  flex: auto 0 0;
  box-sizing: border-box;
  display: flex;
`;

Col.defaultProps = {
  width: [1, 1 / 2, 1 / 3],
  px: 2,
  mb: 3
};

import React, { useMemo } from "react";
// @ts-ignore
import LJSX from "@literal-jsx/parser";

import KNOWN from "~/content-components";

class NotKnown extends Error {
  ljsxdata: {
    name: string;
    attributes: null | { [attr: string]: any };
    children: any[];
  };

  constructor(
    name: string,
    attributes: null | { [attr: string]: any },
    children: any[]
  ) {
    super("not known");
    this.ljsxdata = {
      name,
      attributes,
      children
    };
  }
}

function h(
  name: string,
  attributes: null | { [attr: string]: any },
  ...children: any[]
) {
  const ContentComponent = (KNOWN as any)[name];
  if (!ContentComponent) {
    throw new NotKnown(name, attributes, children);
  } else {
    return React.createElement(ContentComponent, attributes, ...children);
  }
}

interface Props {
  source: string;
  inline?: boolean;
}

export default function LJSXRenderer({ source, inline }: Props) {
  return useMemo(() => {
    try {
      return LJSX.parseValue(source, h);
    } catch (error) {
      if (error instanceof NotKnown) {
        return (
          <AdminError inline={inline}>
            <div>
              Content component <code>&lt;{error.ljsxdata.name}&gt;</code> is
              not known.{" "}
            </div>
          </AdminError>
        );
      } else {
        return (
          <AdminError inline={inline}>
            <div>Could not parse LJSX:</div>
            <pre>{source}</pre>
          </AdminError>
        );
      }
    }
  }, [source, inline]);
}

function AdminError(props: any) {
  console.warn("admin error", props);
  return null;
}

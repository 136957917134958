import React, { useCallback, useEffect } from "react";
import { Link, LinkProps, useLocation } from "react-router-dom";

// There are two options here:
// (1) Make the scrolling-to-top a characteristic of the link
// (2) Make the scrolling-to-top a characteristic of the page

export function ScrollTopLink({ onClick: _onClick, ...rest }: LinkProps) {
  const onClick = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      if (_onClick) {
        _onClick(event);
      }
      window.scrollTo(0, 0);
    },
    [_onClick]
  );

  return <Link {...rest} onClick={onClick} />;
}

interface Props {
  children?: React.ReactNode;
}

export function ScrollToTop({ children = null }: Props) {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return <>{children}</>;
}

import React from "react";
import styled from "styled-components";

import { useContentContext } from "~/lib/content_context";
import { Box } from "./basics";

const scores = [1,2,3,4,5];
const ratingLink = (path: string, score: number) => 
  `https://docs.google.com/forms/d/e/1FAIpQLSclx3CTXnSXQhAxjV_b_dSe2MKZ7jnrAS83Wpi1Vn6hEfRw3A/viewform?usp=pp_url&entry.2430929=${path}&entry.1157968139=${score}`;

export function ContentScoreFeedback() {
  const { at } = useContentContext();

  return (
    <ContentScoreFeedbackWrapper mt={5}>
      <p>How would you rate the content on this page?</p>
      <Box mt={5}>
        {scores.map(score =>
          <a key={score} href={ratingLink(at.path, score)} target="_blank">{score}</a>
        )}
      </Box>
    </ContentScoreFeedbackWrapper>
  );
}

export const ContentScoreFeedbackWrapper = styled(Box)`
border-top: 1px solid ${p => p.theme.borderColor[0]};
padding: 1.25rem 0;

a {
  border: 1px solid ${p => p.theme.borderColor[0]};
  padding: 1.25rem;
  color: ${p => p.theme.colors.text};
  text-decoration: none;

  transition: border 0.2s ease;

  &:hover, 
  &:active,
  &:focus {
    outline: none;
    border: 1px solid
      ${p =>
        p.theme.darkMode ? p.theme.baseColors.yellow : p.theme.baseColors.red};
  }
}
`;

import React from "react";
import cx from "classnames";
import ReactMarkdown, { renderers, NodeType } from "react-markdown";
import { Link } from "react-router-dom";

import remarkLJSXPlugin from "./lib/remarkLJSXPlugin";
import LJSXRenderer from "./lib/LJSXRenderer";

import MarkdownParseErrorBoundary from "./MarkdownParseErrorBoundary";
import CodeBlock from "./CodeBlock";

interface Props {
  source: string; // markdown
  contentComponents?: boolean;
  inline?: boolean;
}

function Markdown({ source, contentComponents = false, inline }: Props) {
  let containerTagName = "div";
  let unwrapDisallowed = false;
  let disallowedTypes: NodeType[] = [];

  if (inline) {
    containerTagName = "span";
    unwrapDisallowed = true;
    disallowedTypes = [
      "paragraph",
      "thematicBreak",
      "blockquote",
      "image",
      "imageReference",
      "table",
      "tableHead",
      "tableBody",
      "tableCell",
      "tableRow",
      "list",
      "listItem",
      "definition",
      "heading",
      "code",
    ];
  }

  return (
    <MarkdownParseErrorBoundary>
      <ReactMarkdown
        source={source}
        skipHtml={false}
        escapeHtml={false}
        unwrapDisallowed={unwrapDisallowed}
        disallowedTypes={disallowedTypes}
        renderers={{
          LJSX: contentComponents
            ? LJSXRenderer
            : () => {
                console.warn(
                  "Content components not allowed in this bit of markdown"
                );
                return null;
              },
          ...renderers,
          table(props) {
            props = {
              ...props,
              className: cx(props.className, "table table-bordered"),
            };
            return (
              <div className='table-container'>
                <table {...props} />
              </div>
            );
          },
          link(props) {
            if ("href" in props && ("" + props.href).match(/^\/courses/)) {
              const { href: to, ...rest } = props;
              return <Link {...rest} to={to} />;
            } else {
              return <a {...props} target='_blank' />;
            }
          },
          root({ children, ...props }) {
            return React.createElement(
              containerTagName,
              {
                ...props,
                className: cx(props.className, "markdown-content"),
              },
              children
            );
          },
          code: CodeBlock,
        }}
        plugins={[remarkLJSXPlugin]}
      />
    </MarkdownParseErrorBoundary>
  );
}

export default React.memo(Markdown);

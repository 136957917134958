// @ts-ignore
import { makeLexer, Parser } from "@literal-jsx/parser";

/**
 * This (remark tokenizer) plugin reads any Literal JSX
 *  element with a capitalized name, i.e. the source starts
 *  with a "<" followed by a capital letter.
 * It then eats up all input until that element is fully read,
 *  or until lex/parse error occurs.
 * The renderer will have to deal with parsing,
 *  error handling, etc.
 */

function locator(value: string, fromIndex: number) {
  const m = value.slice(fromIndex).match(/<[A-Z]/);
  return m ? (m.index || 0) + fromIndex : -1;
}

function ljsxTokenizer(inline: boolean = false) {
  return function(eat: Function, value: string) {
    const m = value.match(/^(\s*)(<[A-Z])/);
    if (!m) return;

    const lexer = makeLexer();
    const parser = new Parser();

    lexer.reset(value);
    let error: any = null;
    let t: any = null;
    let ast: any = null;
    while (!error && !ast) {
      try {
        const nextToken = lexer.next();
        if (!nextToken) throw new Error("lexer end");
        t = nextToken;
        parser.feed(t.value);
        if (parser.results && parser.results.length > 0) {
          ast = parser.results[0];
        }
      } catch (e) {
        error = e;
      }
    }

    if (!t) {
      // cannot happen
      return;
    }

    const source = value.slice(0, t.offset + t.value.length);

    return eat(source)({
      type: "LJSX",
      inline,
      source,
      ast
    });
  };
}

export default function remarkLJSXPlugin() {
  // @ts-ignore
  const self = this;

  if (isRemarkParser(self.Parser)) {
    const parser = self.Parser.prototype;

    const blockTokenizers = parser.blockTokenizers;
    const blockMethods = parser.blockMethods;

    blockTokenizers.ljsx = ljsxTokenizer();
    blockMethods.splice(blockMethods.indexOf("html"), 0, "ljsx");

    const inlineTokenizers = parser.inlineTokenizers;
    const inlineMethods = parser.inlineMethods;

    inlineTokenizers.ljsx = ljsxTokenizer(true);
    inlineTokenizers.ljsx.locator = locator;
    inlineMethods.splice(inlineMethods.indexOf("html"), 0, "ljsx");
  }
}

function isRemarkParser(parser: any) {
  return Boolean(
    parser &&
      parser.prototype &&
      parser.prototype.inlineTokenizers &&
      parser.prototype.inlineTokenizers.break &&
      parser.prototype.inlineTokenizers.break.locator
  );
}

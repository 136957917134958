import styled from "styled-components";
import { space, SpaceProps, color, ColorProps } from "styled-system";

export const IconButton = styled.button<ColorProps & SpaceProps>`
  ${space};
  ${color};

  display: flex;
  border: none;
  outline: none;
  background: none;
  border-radius: 999px;

  outline: none;
  cursor: pointer;

  font-size: 1.5rem;

  &:focus {
    outline: none;
    background: ${(p) => (p.theme.darkMode ? "rgba(255, 255, 255, 0.1)" : "rgba(0, 0, 0, 0.1)")};
  }
`;

IconButton.defaultProps = {
  color: "text",
  mt: 0,
  mr: 0,
  mb: 0,
  ml: 0,
  p: 2,
};

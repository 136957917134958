import { useCallback, useMemo } from "react";
import { gql } from "apollo-boost";
import { useSubscription, useQuery } from "@apollo/react-hooks";

import { useAuth } from "./auth";

export const PROGRESS_UPDATES = gql`
  subscription progressUpdates($courseId: String!, $path: String!) {
    readerProgressStatus(where: { courseId: $courseId, pathToContent: $path }) {
      mutation
      node {
        content_path
        student {
          id
          profile {
            first_name
            last_name
          }
        }
      }
    }
  }
`;

const READER_PROGRESS = gql`
  query getReaderProgress($courseId: String!, $pathToContent: String!) {
    statuses: readerProgress(
      where: { courseId: $courseId, pathToContent: $pathToContent }
    ) {
      id
      content_path
      student {
        id
        profile {
          first_name
          last_name
        }
      }
    }
  }
`;

export type ReaderProgressStatus = {
  id: string;
  content_path: string;
  student: {
    id: string;
    profile?: {
      first_name: string;
      last_name: string;
    };
  };
};

export type StudentTrackerAPI = {
  loading: boolean;
  getStudentsAt: (path: string) => ReaderProgressStatus[];
  getStudentsWithin: (path: string) => ReaderProgressStatus[];
};

export default function useStudentTracker(
  course_id?: string,
  day_path?: string
): StudentTrackerAPI {
  // We only want to fetch progress (& updates) if we're teacher/admin
  const { me } = useAuth();
  const teacher = Boolean(me && (me.admin || me.teacher));

  const { data, loading, refetch: refetchProgress } = useQuery<{
    statuses: ReaderProgressStatus[];
  }>(READER_PROGRESS, {
    variables: {
      courseId: course_id,
      pathToContent: day_path
    },
    skip: !course_id || !day_path || !teacher
  });

  // Remove duplicates, which theoretically shouldn't
  //  happen, but pragmatically might in certain cases
  const statuses = useMemo(() => {
    const seen: {
      [student_id: string]: boolean;
    } = {};

    return data
      ? data.statuses.filter(status => {
          if (seen[status.student.id]) {
            return false;
          } else {
            seen[status.student.id] = true;
            return true;
          }
        })
      : [];
  }, [data]);

  // TODO: yes, this is a double subscription.
  // I think this hook is built better than the finnicky useDayProgress,
  //  so it would probably be a more robust start for subsequent work
  useSubscription(PROGRESS_UPDATES, {
    skip: !course_id || !day_path || !teacher,
    variables: {
      courseId: course_id,
      path: day_path
    },
    onSubscriptionData({ subscriptionData }) {
      if (subscriptionData && subscriptionData.data) {
        setTimeout(() => refetchProgress(), 100);
      }
    }
  });

  const getStudentsAt = useCallback(
    (path: string) => {
      return statuses.filter(status => {
        return status.content_path === path;
      });
    },
    [statuses]
  );

  const getStudentsWithin = useCallback(
    (path: string) => {
      return statuses.filter(status => {
        return status.content_path.startsWith(path);
      });
    },
    [statuses]
  );

  const api = { loading, getStudentsAt, getStudentsWithin };

  // @ts-ignore
  window.__st = api;

  return api;
}

import styled from "styled-components";

export const Label = styled.label`
  display: block;
  margin-bottom: 0.25rem;

  font-size: 0.85em;
  color: ${(p) => p.theme.colors.muted};
  font-family: ${(p) => p.theme.fonts.heading};
`;

import { LearningGoals } from "./LearningGoals";
import MultiChoice from "./MultiChoice";
import FlashCards from "./FlashCards";
import { Tagline } from "./Tagline";
import { TeacherContent } from "./TeacherContent";
import { Markdown_TheContentComponent } from "./Markdown";
import { Term } from "./Term";

export default {
  LearningGoals,
  MultiChoice,
  FlashCards,
  Tagline,
  TeacherContent,
  Markdown: Markdown_TheContentComponent,
  Term: Term,
};

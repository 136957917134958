import styled from "styled-components";

import { Box } from "./basics";

export const ContentContainer = styled(Box)`
  box-sizing: border-box;
  width: 50rem;
  max-width: 100vw;
  margin-left: auto;
  margin-right: auto;
`;

ContentContainer.defaultProps = {
  px: [3, 3, 4],
};

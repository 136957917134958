import React, { useContext, createContext } from "react";
import { useLocation } from "react-router-dom";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";

import {
  CourseInfo,
  CourseInfo_fragment,
  ContentMeta,
  ContentMeta_fragment
} from "~/api";

import useStudentTracker, { StudentTrackerAPI } from "./useStudentTracker";

const FIND_BOOTCAMPS = gql`
  query {
    courses {
      ...CourseInfo
    }
  }

  ${CourseInfo_fragment}
`;

const GET_COURSE_META = gql`
  query getCourseMeta($path: String!) {
    courseInfo: course(pathToContent: $path) {
      ...CourseInfo
      content {
        ...ContentMeta
      }
    }
  }

  ${CourseInfo_fragment}
  ${ContentMeta_fragment}
`;

const GET_CONTENT_META = gql`
  query getContentMeta($path: String!) {
    content: courseContent(pathToContent: $path) {
      ...ContentMeta
    }
  }

  ${ContentMeta_fragment}
`;

const GET_DAY_OVERVIEW = gql`
  query getDayOverview($path: String!) {
    day: courseContent(pathToContent: $path) {
      ...ContentMeta

      sections {
        ...ContentMeta
        sections {
          ...ContentMeta
        }
      }
    }
  }

  ${ContentMeta_fragment}
`;

export const LEVEL_ALL_BOOTCAMPS = 0;
export const LEVEL_BOOTCAMP = 1;
export const LEVEL_DAY = 2;
export const LEVEL_CHAPTER = 3;
export const LEVEL_SECTION = 4;

export interface ContentContextInfo {
  courseInfo?: CourseInfo;
  breadcrumbs: ContentMeta[]; // allcoursesfakenode, course.content?, day?, chapter?, section?
  day?: ContentMeta & {
    sections?: Array<
      ContentMeta & {
        sections?: ContentMeta[];
      }
    >;
  };
  studentTracker: StudentTrackerAPI;

  // derived
  at: ContentMeta;
  courseMeta?: ContentMeta;
  dayMeta?: ContentMeta;
  chapterMeta?: ContentMeta;
  sectionMeta?: ContentMeta;
}

const ContentContext = createContext<ContentContextInfo>(null as any);

interface Props {
  children?: React.ReactNode;
}

export function ContentContextProvider({ children = null }: Props) {
  const { pathname } = useLocation();
  const [course_slug, day_slug, chapter_slug, section_slug] = (
    pathname.match(
      /^\/courses(?:\/([^/]+))?(?:\/([^/]+))?(?:\/([^/]+))?(?:\/([^/]+))?/
    ) || []
  ).slice(1);

  const { data: { courses } = { courses: undefined } } = useQuery<{
    courses: CourseInfo[];
  }>(FIND_BOOTCAMPS);

  const allBootcampsFakeMetaNode = {
    id: "fake",
    title: "Bootcamps",
    description: "All bootcamps",
    path: "/",
    tags: [],

    sections: courses
      ? courses.map(course => {
          return {
            id: course.id,
            title: course.name,
            description: course.description,
            path: course.pathToContent
          };
        })
      : undefined
  };

  const { data: { courseInfo } = { courseInfo: undefined } } = useQuery<{
    courseInfo: CourseInfo & {
      content: ContentMeta;
    };
  }>(GET_COURSE_META, {
    variables: { path: `/courses/${course_slug}` },
    skip: !course_slug
  });

  const courseMeta = courseInfo ? courseInfo.content : undefined;

  const { data: { day } = { day: undefined } } = useQuery<{
    day: ContentMeta & {
      sections?: Array<
        ContentMeta & {
          sections?: ContentMeta[];
        }
      >;
    };
  }>(GET_DAY_OVERVIEW, {
    variables: { path: `/courses/${course_slug}/${day_slug}` },
    skip: !course_slug || !day_slug
  });

  const dayMeta = day as ContentMeta;

  const { data: { content: chapterMeta } = { content: undefined } } = useQuery<{
    content: ContentMeta;
  }>(GET_CONTENT_META, {
    variables: { path: `/courses/${course_slug}/${day_slug}/${chapter_slug}` },
    skip: !course_slug || !day_slug || !chapter_slug
  });

  const { data: { content: sectionMeta } = { content: undefined } } = useQuery<{
    content: ContentMeta;
  }>(GET_CONTENT_META, {
    variables: {
      path: `/courses/${course_slug}/${day_slug}/${chapter_slug}/${section_slug}`
    },
    skip: !course_slug || !day_slug || !chapter_slug || !section_slug
  });

  const studentTracker = useStudentTracker(
    courseInfo && courseInfo.id,
    dayMeta && dayMeta.path
  );

  const breadcrumbs = [
    allBootcampsFakeMetaNode,
    courseMeta,
    courseMeta && dayMeta,
    courseMeta && dayMeta && chapterMeta,
    courseMeta && dayMeta && chapterMeta && sectionMeta
  ].filter(Boolean) as ContentMeta[];

  const at =
    sectionMeta ||
    chapterMeta ||
    dayMeta ||
    courseMeta ||
    allBootcampsFakeMetaNode;

  return (
    <ContentContext.Provider
      value={{
        courseInfo,
        breadcrumbs,
        day,
        studentTracker,
        at,
        courseMeta,
        dayMeta,
        chapterMeta,
        sectionMeta
      }}
    >
      {children}
    </ContentContext.Provider>
  );
}

export function useContentContext() {
  return useContext(ContentContext);
}

import React, { useCallback, useState } from "react";
import styled from "styled-components";

import AsTeacher from "~/components/AsTeacher";
import Markdown from "~/components/Markdown";

interface Props {
  children: string | React.ReactNode;
}

export function TeacherContent({ children = null }: Props) {
  const [open, set_open] = useState(false);

  const toggle = useCallback(() => {
    set_open(b => !b);
  }, [set_open]);

  const getContent = useCallback(() => {
    return typeof children === "string" ? <Markdown source={children} /> : children;
  }, [children]);

  return (
    <AsTeacher>
      <TeacherContentBox>
        <LabelButton onClick={toggle}>Teacher content</LabelButton>
        {open ? <div style={{ padding: "1.5rem" }}>{getContent()}</div> : null}
      </TeacherContentBox>
    </AsTeacher>
  );
}

const LabelButton = styled.button`
  all: initial;
  position: absolute;
  top: -1.25rem;

  cursor: pointer;

  font-family: ${p => p.theme.fonts.heading};
  color: ${p => p.theme.baseColors.blue};
  text-transform: uppercase;
`;

const TeacherContentBox = styled.div`
  margin: 2.5rem -0.5rem 1rem;
  position: relative;

  border: 2px solid ${p => p.theme.baseColors.blue};
`;

import { ContentContainer } from "~/ui";
import Markdown from "~/components/Markdown";
import React from "react";
import { stripIndent } from "common-tags";

export default function HelpPage() {
  return (
    <main style={{ flexGrow: 1, marginBottom: "6rem" }}>
      <ContentContainer mt={[4, 5]}>
        <Markdown
          contentComponents
          source={stripIndent`
            # Help!

            <TeacherContent>
              You can edit this page directly [here](https://github.com/Codaisseur/minimalist_reader/blob/master/src/pages/HelpPage.tsx).
            </TeacherContent>

            ### I can't log in anymore

            That is correct. All course materials have moved to [Kyna](https://kynacademy.com/). Reach out to them via support@kynacademy.com if you want access to 
            your (old) course materials. Be sure to mention in your email: 1) Your name, 2) your Codaisseur class number, and 3) the email address you used to log into your
            Reader account.

            ---

            If you're in doubt, you can always reach out to anyone from the Codaisseur team directly (on Slack), and we'll make sure that your problem is solved.
          `}
        />
      </ContentContainer>
    </main>
  );
}

import styled from "styled-components";
import { color, ColorProps } from "styled-system";

export const Tagline = styled.p<ColorProps>`
  ${color};

  font-family: ${(p) => p.theme.fonts.serif};
  font-weight: 600;
  font-style: italic;

  font-size: 1.25em;
  line-height: 150%;
`;

Tagline.defaultProps = {
  color: "muted",
};

import styled from "styled-components";
import { Link } from "react-router-dom";

export const CardLink = styled(Link)`
  display: block;
  flex: 1;
  border: 1px solid ${p => p.theme.borderColor[0]};
  border-radius: 6px;
  padding: 1.25rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);

  font-weight: normal;
  color: ${p => p.theme.colors.text};
  text-decoration: none;

  transition: transform 0.2s ease;

  &:hover {
    transform: translate(0, -1px) scale(1.01);
  }

  &:focus {
    outline: none;
    border: 1px solid
      ${p =>
        p.theme.darkMode ? p.theme.baseColors.yellow : p.theme.baseColors.red};
    box-shadow: 0 0.2rem 0.42rem rgba(0, 0, 0, 0.13),
      0 0 0 1px
        ${p =>
          p.theme.darkMode
            ? p.theme.baseColors.yellow
            : p.theme.baseColors.red};
  }

  &:active {
    transform: translate(0, -1px) scale(0.99);
  }

  h2 {
    color: ${p =>
      p.theme.darkMode ? p.theme.baseColors.white : p.theme.baseColors.red};
  }
`;

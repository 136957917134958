import React from "react";

import { useContentContext } from "~/lib/content_context";

import { Loader, Tag, Box } from "~/ui";
import Markdown from "~/components/Markdown";

export function LearningGoals() {
  const { at } = useContentContext();

  if (!at) return <Loader />;

  return (
    <>
      {at.learning_goals && at.learning_goals.length > 0 && (
        <>
          <h2>🎯 Learning Goals</h2>
          <ul>
            {at.learning_goals.map((lg, i) => {
              return (
                <li key={i}>
                  <Markdown source={lg.description} />
                </li>
              );
            })}
          </ul>
        </>
      )}
      {at.new_terminology && at.new_terminology.length > 0 && (
        <>
          <h3>New terminology</h3>
          <Box as="p" mx={-1}>
            {at.new_terminology.map((term, i) => {
              return (
                <Tag key={i} tag={term} m={1}>
                  <Markdown inline source={term} />
                </Tag>
              );
            })}
          </Box>
        </>
      )}
    </>
  );
}

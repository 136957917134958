import React, { useState } from "react";

import { Box } from "~/ui";
import styled from "styled-components";

const DEV_API_URL = "http://localhost:1235/graphql";

export default function Footer() {
  const [devMode, setDevMode] = useState(localStorage.API_URL === DEV_API_URL);

  const toggleDevMode = () => {
    if (!devMode) {
      localStorage.API_URL = DEV_API_URL;
      window.location.reload();
    } else {
      localStorage.removeItem("API_URL");
      window.location.href = "/";
    }
    setDevMode(!devMode);
  };

  return (
    <Box id="footer" style={{ position: "relative" }}>
      <Gradient />
      <Content>
        <div style={{ flexGrow: 1 }} />
        <div>Codaisseur Reader 4.0</div>
      </Content>
    </Box>
  );
}

const Gradient = styled.div`
  height: 11px;
  background-image: linear-gradient(
    to right,
    #d7461a,
    #c1272d,
    #733782,
    #1f28ce,
    #0088fa
  );
`;

const Content = styled.div`
  display: flex;
  padding: 1rem 1.5rem;

  background: ${(p) =>
    p.theme.darkMode
      ? p.theme.colors.backgroundAlt
      : p.theme.baseColors.gray800};
  color: ${(p) => p.theme.baseColors.gray200};
`;

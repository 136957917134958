import React, { createContext, useCallback, useContext, useState } from "react";
import { gql } from "apollo-boost";
import { useQuery, useMutation } from "@apollo/react-hooks";

const AUTH_TOKEN = "AUTH_TOKEN";

export const getToken = () => localStorage.getItem(AUTH_TOKEN);
export const setToken = (token: string) => localStorage.setItem(AUTH_TOKEN, token);
export const deleteToken = () => localStorage.removeItem(AUTH_TOKEN);

const GET_ME = gql`
  {
    me {
      id
      legacy_id
      email
      profile {
        first_name
        last_name
      }
      teacher
      admin
      student
      hash
    }
  }
`;

export const LOG_IN = gql`
  mutation logIn($email: String!, $password: String!) {
    signIn(data: { email: $email, password: $password }) {
      token
      user {
        id
        legacy_id
        email
        profile {
          first_name
          last_name
        }
        teacher
        admin
        student
        hash
      }
    }
  }
`;

export const SIGN_UP = gql`
  mutation SignUp($firstName: String!, $lastName: String!, $email: String!, $password: String!) {
    signUp(
      data: {
        first_name: $firstName
        last_name: $lastName
        email: $email
        password: $password
        password_confirmation: $password
      }
    ) {
      id
    }
  }
`;

export const UNLOCK_ACCOUNT = gql`
  mutation UnlockAccount($email: String!, $token: String!) {
    message: unlockAccount(data: { email: $email, token: $token }) {
      type
      message
    }
  }
`;

export const CONFIRM_EMAIL = gql`
  mutation ConfirmEmail($token: String!) {
    message: confirmEmailAddress(data: { token: $token }) {
      type
      message
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation ForgotPassword($email: String!) {
    message: forgotPassword(data: { email: $email }) {
      type
      message
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword($token: String!, $password: String!, $password_confirmation: String!) {
    message: resetPassword(
      data: { token: $token, password: $password, password_confirmation: $password_confirmation }
    ) {
      type
      message
    }
  }
`;

export interface Me {
  id: string;
  legacy_id: string;
  email: string;
  profile?: {
    first_name: string;
    last_name: string;
  };
  teacher: boolean;
  admin: boolean;
  student: boolean;
  hash: string;
}

export type Message = {
  type: "INFO" | "SUCCESS" | "WARNING" | "ERROR";
  message: string;
};

interface AuthState {
  me?: Me;
  signup: (data: {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
  }) => Promise<void>;
  forgotPassword: (data: { email: string }) => Promise<Message>;
  resetPassword: (data: {
    token: string;
    password: string;
    password_confirmation: string;
  }) => Promise<Message>;
  login: (credentials: { email: string; password: string }) => Promise<Me>;
  logout: () => Promise<void>;
  bootstrapping: boolean;
}

const AuthContext = createContext<AuthState>({
  signup() {
    throw new Error("Wrap your app with <AuthProvider> first");
  },
  forgotPassword() {
    throw new Error("Wrap your app with <AuthProvider> first");
  },
  resetPassword() {
    throw new Error("Wrap your app with <AuthProvider> first");
  },
  login() {
    throw new Error("Wrap your app with <AuthProvider> first");
  },
  logout() {
    throw new Error("Wrap your app with <AuthProvider> first");
  },
  bootstrapping: false,
});

interface Props {
  children?: React.ReactNode;
}

export function AuthProvider({ children = null }: Props) {
  const { data, loading: bootstrapping, client } = useQuery(GET_ME);
  const me = data ? data.me : null;

  const logout = useCallback(() => {
    async function doLogout() {
      await client.cache.reset();
      deleteToken();
      client.writeData({
        data: {
          me: null,
        },
      });
    }
    return doLogout();
  }, [client]);

  const [_logIn] = useMutation(LOG_IN);

  const login = useCallback(
    async ({ email = "", password = "" }) => {
      const {
        data: {
          signIn: { token, user: me },
        },
      } = await _logIn({
        variables: { email, password },
      });
      setToken(token);
      client.writeData({
        data: {
          me,
        },
      });
      return me;
    },
    [client]
  );

  const [_signUp] = useMutation(SIGN_UP);

  const signup = useCallback(
    async ({ firstName = "", lastName = "", email = "", password = "" }) => {
      await _signUp({
        variables: { firstName, lastName, email, password },
      });
    },
    [client]
  );

  const [_forgotPassword] = useMutation(FORGOT_PASSWORD);

  const forgotPassword = useCallback(
    async ({ email = "" }) => {
      const r = await _forgotPassword({
        variables: { email },
      });
      return r.data.message;
    },
    [client]
  );

  const [_resetPassword] = useMutation(RESET_PASSWORD);

  const resetPassword = useCallback(
    async ({ token = "", password = "", password_confirmation = "" }) => {
      const r = await _resetPassword({
        variables: { token, password, password_confirmation },
      });
      return r.data.message;
    },
    [client]
  );

  return (
    <AuthContext.Provider
      value={{
        me,
        forgotPassword,
        resetPassword,
        signup,
        login,
        logout,
        bootstrapping,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}

import { useState, useEffect, useCallback } from "react";
import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/react-hooks";

import { useContentContext } from "./content_context";

export const MY_EXERCISE_RESPONSE = gql`
  query myExerciseResponse($exercise: String!, $context: String!) {
    myExerciseResponse(data: { exercise: $exercise, context: $context }) {
      id
      exercise
      context
      input
      evaluation {
        score
        passed
      }
      createdAt
    }
  }
`;

export const SAVE_EXERCISE_RESPONSE = gql`
  mutation saveExerciseResponse($data: SaveExerciseResponseInput!) {
    savedExerciseResponse: saveExerciseResponse(data: $data) {
      id
      exercise
      context
      input
      evaluation {
        score
        passed
      }
      createdAt
    }
  }
`;

export interface ExerciseResponse {
  id: string;
  exercise: string;
  context: string;
  input: any;
  createdAt?: any; // todo
  evaluation: ExerciseEvaluation;
}

export interface ExerciseEvaluation {
  score?: number;
  passed?: boolean;
}

export interface ResponseState<T> {
  loading: boolean;
  value: T;
  evaluation?: any;
}

/**
 * This is a utility helper for displaying exercise (content) component
 *  that save their answers via the crystal "exerciseresponse" API.
 *
 * Things to note:
 *  - An exercise is considered unique up to its ID (`exercise`)
 *    + the current "viewing" content path (`context`). This is so that
 *    the exercise can be reused via regular course content imports.
 *
 * API:
 *
 *     const [
 *       responseState: { loading: boolean; value: T },
 *       respond: (input: T, evaluation: ExerciseEvaluation) => void
 *     ] = useExerciseResponder<T>(
 *       exerciseId: string,
 *       defaultValue: T
 *     )
 */
export default function useExerciseResponder<T = any>(
  exercise: string,
  fallbackValue: T
): [ResponseState<T>, Function] {
  const { courseInfo, at } = useContentContext();
  const course = courseInfo ? courseInfo.id : "[unknown]";
  const context = at ? at.path : "[unknown]";

  const [state, setState] = useState<ResponseState<T>>({
    loading: true,
    value: fallbackValue
  });

  const { data, loading } = useQuery<{
    myExerciseResponse?: ExerciseResponse;
  }>(MY_EXERCISE_RESPONSE, {
    variables: {
      exercise,
      context
    },
    fetchPolicy: "network-only"
  });

  useEffect(() => {
    if (!loading) {
      let value = state.value;
      if (data && data.myExerciseResponse) {
        value = data.myExerciseResponse.input;
      }
      setState({
        loading: false,
        value,
        evaluation:
          data && data.myExerciseResponse && data.myExerciseResponse.evaluation
      });
    }
    // eslint-disable-next-line
  }, [data, loading]);

  const [saveResponse] = useMutation(SAVE_EXERCISE_RESPONSE, {
    update(cache, { data }) {
      cache.writeQuery({
        query: MY_EXERCISE_RESPONSE,
        variables: {
          exercise,
          context
        },
        data: {
          myExerciseResponse: data.savedExerciseResponse
        }
      });
    }
  });

  const respond = useCallback(
    (input: T, evaluation: ExerciseEvaluation) => {
      setState({ loading: true, value: input });
      saveResponse({
        variables: {
          data: {
            course,
            exercise,
            context,
            input: JSON.stringify(input),

            evaluation
          }
        }
      })
        .then(({ data: { savedExerciseResponse } }) => {
          // woo!
        })
        .catch(() => {
          // too bad
        })
        .finally(() => {
          setState({ loading: false, value: input });
        });
    },
    [exercise, context, course, saveResponse]
  );

  return [state, respond];
}
